import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { Layout, Magazines, Pagination } from "../../components"

const WritingMagazinesPage = ({ data, pageContext }) => {
  const {
    allContentfulMagazine: { nodes: mags },
  } = data

  // console.log("poems.js data", data)
  // console.log("poems.js pagecontext", pageContext)

  return (
    <Wrapper>
      <Layout>
        <Magazines
          title="Összes írás (magazinok)"
          mags={mags}
          page
          link="writing"
        />
        <Pagination pageContext={pageContext} />
      </Layout>
    </Wrapper>
  )
}

const Wrapper = styled.main`
  min-height: 100vh;
  ${"" /* background from Poems.js  */}
  background: var(--clr-dark-5);
  padding-top: 5rem;
  nav {
    background-color: rgba(0, 0, 0, 0.85);
  }
`

export const query = graphql`
  query MagazinesWritingQuery($skip: Int, $limit: Int) {
    allContentfulMagazine(
      filter: { author: { authorFullName: { eq: "Pék Zoltán" } } }
      limit: $limit
      skip: $skip
      sort: { fields: createdAt, order: DESC }
    ) {
      nodes {
        contentful_id
        textTitle
        author {
          authorFullName
        }
        publisher {
          publisherName
        }
        magazinePublished(formatString: "YYYY-MM")
        textSize
        magazineImage {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG)
        }
      }
    }
  }
`

export default WritingMagazinesPage
